import React from 'react';
import { useForm } from "react-hook-form";

import { patchPropertyReject } from "../../../../../api/services/property/requests";

import { IPatchRejectProperty } from "../../../../../api/services/property/types";

import useGlobalLoaderStore from "../../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";

import Button from "../../../../../common/components/Button/Button";
import Textarea from "../../../../../common/components/Textarea/Textarea";

interface IPropertyReject {
  rejectReason: string;
}

interface RejectPropertyModalProps {
  id: string;
}

const RejectPropertyModal: React.FC<RejectPropertyModalProps> = ({ id }) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const defaultValues = {
    rejectReason: '',
  }

  const {
    control,
    handleSubmit,
    watch,
  } = useForm<IPropertyReject>({
    mode: "onBlur",
    defaultValues,
  });

  const rejectReason = watch('rejectReason');

  const onSubmit = async (value: IPropertyReject) => {
    const payload: IPatchRejectProperty = {
      rejectReason: value.rejectReason,
    }

    try {
      setLoader(true)
      await patchPropertyReject(id, payload);
      onClose()
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid gap-5 w-[572px]">
      <div className="flex justify-between items-center">
        <div className="text-[#333] text-[18px] leading-5 font-bold">Reject property</div>
      </div>
      <div>
        <Textarea
          label="Reject reason"
          name="rejectReason"
          control={control}
          className="bg-[#f5f5f5]"
        />
      </div>
      <div className="flex items-center justify-end">
        <Button color="red" className="w-[198px] h-[48px] rounded-[8px] text-[16px] font-bold" disabled={!rejectReason?.length} type="submit">Reject</Button>
      </div>
    </form>
  );
};

export default RejectPropertyModal;
