import React from "react";

import useGlobalModalStore from "../../common/stores/useGlobalModalStore";

import Input from "../../common/components/Input/Input";
import PhoneInput from "../../common/components/PhoneInput/PhoneInput";
import Button from "../../common/components/Button/Button";
import Textarea from "../../common/components/Textarea/Textarea";

import useFormEditAccount from "../../features/account/hooks/useFormEditAccount";

import AvatarDropzone from "../../features/account/components/AvatarDropzone";
import ChangePasswordModalForm from "../../features/account/components/modals/ChangePasswordModalForm/ChangePasswordModalForm";
import ChangeEmailModalForm from "../../features/account/components/modals/ChangeEmailModalForm/ChangeEmailModalForm";

const AccountPage = () => {
  const { setIsOpen, setChildren } = useGlobalModalStore();

  const {
    control,
    handleSubmit,
    isValid,
    avatar,
    setAvatar,
  } = useFormEditAccount();

  const openChangePasswordModal = (e: any) => {
    e.preventDefault();
    setChildren(<ChangePasswordModalForm />);
    setIsOpen(true);
  }

  const openChangeEmailModal = (e: any) => {
    e.preventDefault();
    setChildren(<ChangeEmailModalForm />);
    setIsOpen(true);
  }

  return (
    <div className="p-[18px_25px_26px_25px]">
      <form onSubmit={handleSubmit} className="grid gap-[24px] h-full p-5 pr-[57px] rounded-[15px] overflow-x-auto bg-white">
        <div className="grid grid-cols-[auto_1fr] gap-[24px]">
          <AvatarDropzone avatar={avatar} setAvatar={setAvatar} />
          <div className="grid grid-cols-[400px_400px] gap-[24px]">
            <Input
              label="Full Name"
              name="name"
              control={control}
              placeholder="Enter your full name"
            />
            <PhoneInput
              label="Phone number"
              name="phone"
              control={control}
              disabled
              defaultCountry="CZ"
              placeholder="Enter phone number"
            />
            <Input
              label="Email"
              name="email"
              control={control}
              disabled
              placeholder="Email your email address"
            />
          </div>
        </div>
        <div className="flex items-center gap-6">
          <button onClick={openChangePasswordModal} className="flex-center w-[170px] h-[48px] border border-[#006CFB] rounded-[8px] font-bold text-[14px] text-[#006CFB]">Change password</button>
          <button onClick={openChangeEmailModal} className="flex-center w-[170px] h-[48px] border border-[#006CFB] rounded-[8px] font-bold text-[14px] text-[#006CFB]">Change Email</button>
        </div>
        <div className="grid grid-cols-[400px_400px] gap-[24px]">
          <Input
            label="Legal name"
            name="legalName"
            control={control}
            placeholder="Enter legal name"
          />
          <Input
            label="Address"
            name="address"
            control={control}
            placeholder="Enter street, building number"
          />
          <Input
            label="IČ"
            name="ic"
            control={control}
            placeholder="Enter IČ"
          />
          {/*<Input*/}
          {/*  label="City"*/}
          {/*  name="city"*/}
          {/*  control={control}*/}
          {/*  placeholder="Enter city"*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  label="DIČ"*/}
          {/*  name="dic"*/}
          {/*  control={control}*/}
          {/*  placeholder="Enter DIČ"*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  label="Postal code"*/}
          {/*  name="postalCode"*/}
          {/*  control={control}*/}
          {/*  placeholder="Enter postal code"*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  label="Email"*/}
          {/*  name="companyEmail"*/}
          {/*  control={control}*/}
          {/*  placeholder="Enter email"*/}
          {/*/>*/}
          <PhoneInput
            label="Company Phone number"
            name="companyPhone"
            control={control}
            defaultCountry="CZ"
            placeholder="Enter company phone number"
          />
        </div>
        <div>
          <Textarea
            label="Description"
            name="description"
            control={control}
            placeholder="Write about the agency"
            className="bg-[#fff]"
          />
        </div>
        <div className="grid grid-cols-[400px_400px] gap-[24px]">
          <Input
            label="Web"
            name="web"
            control={control}
            placeholder="Enter your website"
          />
        </div>
        <div className="grid grid-cols-[1fr_1fr_1fr_1fr] gap-[24px]">
          <Input
            label="Instagram"
            name="instagram"
            control={control}
            placeholder="@"
          />
          <Input
            label="Twitter"
            name="twitter"
            control={control}
            placeholder="@"
          />
          <Input
            label="Facebook"
            name="facebook"
            control={control}
            placeholder="@"
          />
          <Input
            label="TikTok"
            name="tikTok"
            control={control}
            placeholder="@"
          />
        </div>
        <div className="flex justify-end">
          <Button className="w-[358px] h-[48px] text-[16px] font-bold" disabled={!isValid} type="submit">Update profile</Button>
        </div>
      </form>
    </div>
  )
}

export default AccountPage;
