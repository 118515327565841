import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { NumberFormatValues, NumericFormat } from "react-number-format";

// import useGlobalModalStore from "../../common/stores/useGlobalModalStore";

// import DragSlider from "../../common/components/DragSlider/DragSlider";

import PublicationsPlanCard from "../../features/billing/components/PublicationsPlanCard";
// import BillingModalForm from "../../features/billing/components/modals/BillingModalForm/BillingModalForm";

// import RIcon from "../../common/assets/icons/RIcon";
import MasterCard from "../../common/assets/icons/MasterCard";
import Visa from "../../common/assets/icons/Visa";

// const pricePerCoin = 34;

//todo: make components
const BillingPage: React.FC = () => {
  const [showMore, setShowMore] = useState(false)
  // const [recoins, setRecoins] = useState(80);

  // const { setIsOpen, setChildren } = useGlobalModalStore();

  // const price = recoins * pricePerCoin;

  // const handleValueChange = (newRecoins: number) => {
  //   setRecoins(newRecoins);
  // };
  //
  // const handleInputChange = (v: NumberFormatValues) => {
  //   setRecoins(v?.floatValue || 0);
  // }

  // const openModal = () => {
  //   setChildren(<BillingModalForm fullPrice={price} />);
  //   setIsOpen(true);
  // }

  return (
    <div className="h-full p-[18px_25px_26px_25px] min-h-[86vh]">
      <div className="h-full py-5 px-[38px] rounded-[15px] overflow-x-auto bg-white">
        <div>
          <div className="mb-[45px]">
            <h2 className="text-[18px] text-[#11142D] font-semibold mb-[10px]">Buy publikace</h2>
            <div className="text-[#666666]">Each package includes a set of listings, with one listing equal to one ad placement for a month. Purchased listings can be stored indefinitely without an expiration date, allowing you to use them whenever needed.</div>
          </div>

          <div className="flex-center gap-[25px] mb-[22px]">
            <PublicationsPlanCard amount={5} price={1500} />
            <PublicationsPlanCard amount={10} price={2000} />
            <PublicationsPlanCard amount={20} price={2500} />
            <PublicationsPlanCard amount={50} price={3500} />
            <PublicationsPlanCard amount={100} price={6000} />
          </div>
          {showMore && (
            <div className="flex-center gap-[25px] mb-[22px]">
              <PublicationsPlanCard amount={200} price={10000} />
              <PublicationsPlanCard amount={500} price={23000} />
              <PublicationsPlanCard amount={1000} price={40000} />
            </div>
          )}
          <div className="mb-[45px]">
            <button className="flex-center w-[180px] h-[48px] border border-blue rounded-[8px] font-bold text-blue mx-auto mb-1" onClick={() => setShowMore(prevShowMore => !prevShowMore)}>{showMore ? 'Hide' : 'Show more'}</button>
            <div className="text-[10px] text-[#66687B] text-right">
              Prices are shown without VAT
            </div>
          </div>
        </div>
        {/*<div className="grid grid-cols-[auto_1fr] items-center gap-10 mb-[45px]">*/}
        {/*  <div className="w-[583px] p-[16px_20px_20px] shadow-[0px_4px_10px_0px_#5050500D] rounded-[20px]">*/}
        {/*    <div className="mb-[10px]">*/}
        {/*      <div className="flex justify-between items-baseline">*/}
        {/*        <div>*/}
        {/*          <div className="flex items-center gap-[5px] mb-[6px]">*/}
        {/*            <label className="text-[18px] text-[#2A2A2A]">Buy Publikace</label>*/}
        {/*            <RIcon color="white" background="#006CFB" />*/}
        {/*          </div>*/}
        {/*          <div className="flex items-center gap-1 text-[12px] text-[#A0AFC4]">Price per 1 publikace <span className="text-[18px] text-[#006CFB]">{pricePerCoin} Kč</span></div>*/}
        {/*        </div>*/}
        {/*        <div className="flex items-center gap-2">*/}
        {/*          <div className="flex items-center gap-1 text-[12px] text-[#A0AFC4]">Price <span className="text-[18px] text-[#006CFB] mt-[2px]">{price} Kč</span></div>*/}
        {/*          <button onClick={openModal} className="flex-center w-[55px] h-[29px] rounded-[8px] bg-[#006CFB] text-[12px] text-white" >Pay</button>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <DragSlider value={recoins} onChange={handleValueChange} />*/}
        {/*  </div>*/}
        {/*  <div className="w-full p-5 bg-[#F2F8FF59]">*/}
        {/*    <div className="mb-[25px]">*/}
        {/*      <div className="text-[18px] text-[#2A2A2A] font-medium mb-[5px]">Choose your quantity</div>*/}
        {/*      <div className="text-[12px] text-[#A0AFC4]">For convenience, you can choose your number of publikace to purchase</div>*/}
        {/*    </div>*/}
        {/*    <div className="flex justify-between items-center">*/}
        {/*      <div className="flex items-center gap-[5px]">*/}
        {/*        <NumericFormat*/}
        {/*          thousandSeparator=" "*/}
        {/*          allowNegative={false}*/}
        {/*          onValueChange={handleInputChange}*/}
        {/*          value={recoins.toString()}*/}
        {/*          id="amount"*/}
        {/*          step={1}*/}
        {/*          className="appearance-none w-[88px] h-[27px] text-[14px] border border-[#006CFB] rounded-[4px] text-center outline-none"*/}
        {/*          placeholder="25 000"*/}
        {/*        />*/}
        {/*        <div className="text-[12px] text-[#A0AFC4]">Publikace</div>*/}
        {/*      </div>*/}
        {/*      <div>*/}
        {/*        <div className="flex items-center gap-2">*/}
        {/*          <div className="flex items-center gap-1 text-[12px] text-[#A0AFC4]">Price <span className="text-[18px] text-[#006CFB] mt-[2px]">{price} Kč</span></div>*/}
        {/*          <button onClick={openModal} className="flex-center w-[55px] h-[29px] rounded-[8px] bg-[#006CFB] text-[12px] text-white" >Pay</button>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div>
          <div className="font-medium mb-6">Transaction history</div>
          <div className="grid grid-cols-[1fr_1fr_1fr_auto] justify-between items-center text-[10px] text-[#A0AEC0] font-bold pb-[6px] border-b border-[#E2E8F0] mb-2">
            <div>Method</div>
            <div>Status</div>
            <div>Date</div>
            <div>Receipt</div>
          </div>
          <div className="grid grid-cols-[1fr_1fr_1fr_auto] justify-between items-center">
            <div className="flex items-center gap-4">
              <div><Visa /></div>
              <div>Card Visa  ** 4432</div>
            </div>
            <div className="text-[#48BB78]">Success</div>
            <div className="text-[#666666]">24.09.2024</div>
            <div className="text-[#006CFB]"><Link to={'#'}>Download</Link></div>
            <div className="flex items-center gap-4">
              <div><MasterCard /></div>
              <div>Card Mastercard  ** 4432</div>
            </div>
            <div className="text-[#48BB78]">Success</div>
            <div className="text-[#666666]">24.09.2024</div>
            <div className="text-[#006CFB]"><Link to={'#'}>Download</Link></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillingPage;
