import React from "react";
import { Link } from "react-router-dom";

import LoginForm from "../../features/login/components/LoginForm";
import RegistrationBlock from "../../features/login/components/RegistrationBlock";

import Logo from "../../common/assets/images/Logo";
import SignInIcon from "../../common/assets/icons/SignInIcon";

const LoginPage: React.FC = () => {
  return (
    <div className="flex h-full">
      <RegistrationBlock/>
      <div className="flex flex-col flex-1 justify-between items-center pt-[25px] pb-[18px]">
        <Logo/>
        <div className="w-[358px]">
          <div className="flex items-center gap-3 mb-8">
            <SignInIcon/>
            <div className="text-2xl font-bold">Sign In</div>
          </div>
          <LoginForm/>
        </div>
        <div className="text-[10px] text-center">
          <div className="text-[#9C9AA5]">By signing up to create an account I accept Company’s</div>
          <Link to="/documents">
            <div className="text-[#26203B]">Terms of use & Privacy Policy.</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
