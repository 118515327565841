import { useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { FieldValues, useForm } from "react-hook-form";

import {
  IPatchPropertyByIdFieldPayload,
  IPatchPropertyByIdPayload,
} from "../../../api/services/property/types";
import { patchPropertyById } from "../../../api/services/property/requests";

import { ICategorySchemaGroupItem } from "../../../common/types/dto/category";
import { IProperty, IPropertyField, IPropertyFieldSchemaRadio } from "../../../common/types/dto/property";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import usePropertyEditStore from "../stores/usePropertyEditStore";

const usePropertyFieldsForm = (propertyId: string, schema?: ICategorySchemaGroupItem, propertyFields?: IPropertyField[], property?: IProperty, schemaType?: string) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { step } = usePropertyEditStore();

  const defaultValues = useMemo(() => {
    const fieldValues = schema?.fields.reduce((acc, item) => {
      const propertyField = propertyFields?.find((f) => f.field.id === item.id);

      if (item.type === "RADIO") {
        const value = propertyField?.value ?? null; //(item as IPropertyFieldSchemaRadio).options[0].value;
        return {...acc, [item.name]: value};
      }

      if (item.type === "TAG_SELECT") {
        const tag = propertyField?.tag?.id ?? null;
        return {...acc, [item.name]: tag};
      }

      if (item.type === "TAG_MULTI_SELECT") {
        const tags = propertyField?.tags.map((tag) => tag.id) ?? [];
        return {...acc, [item.name]: tags};
      }

      if (item.type === "SELECT") {
        const value = propertyField?.value ? (item as IPropertyFieldSchemaRadio).options.find(({value}) => value === propertyField?.value) : "";
        return {...acc, [item.name]: value};
      }

      return {
        ...acc,
        [item.name]: propertyField?.value ?? "",
      }
    }, {});

    return {
      ...fieldValues,
      ...(step === 'priceAndRentalTerms' && {
        contactPhone: property?.contactPhone || "",
        additionalPhone: property?.additionalPhone || "",
        price: property?.price,
      })
    };
  }, [schema, step])

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
    watch,
  } = useForm<FieldValues & { contactPhone?: string; additionalPhone?: string; price?: number; }>({
    mode: "onBlur",
    defaultValues,
  });

  const watchedValues = watch();

  const requiredFieldsNames = schema?.fields.filter(field => field.meta?.isRequired).map(field => field.name);
  const filledFieldNames = [
    ...(propertyFields?.filter(item => !!item.value).map(item => item.field.name) || []),
    ...(Object.entries(watchedValues).filter(([_, value]) => !!value).map(([key]) => key) || [])
  ];
  const hardcodedRequiredFieldsPriceAndRentalTerms = schemaType === 'priceAndRentalTerms' ? (!!watchedValues?.contactPhone || !!property?.contactPhone ? 0 : 1) + (!!watchedValues?.price || !!property?.price ? 0 : 1) : 0;
  const requiredFieldsCount = (requiredFieldsNames?.filter(fieldName => !filledFieldNames?.includes(fieldName)).length || 0) + hardcodedRequiredFieldsPriceAndRentalTerms;

  const { mutateAsync } = useMutation(async ({ propertyId, payload }: {
    propertyId: string,
    payload: IPatchPropertyByIdPayload
  }) => {
      setLoader(true);
      const data = await patchPropertyById(propertyId, payload);
      setLoader(false);
      return data;
  });

  const onSubmit = async (values: any) => {
    if (!isDirty) return;

    const payloadField = Object.entries(values).reduce<IPatchPropertyByIdFieldPayload[]>((acc, [key, value]) => {
      const fieldSchema = schema?.fields.find((f) => f.name === key);
      if (fieldSchema) {
        switch (fieldSchema.type) {
          case "TAG_SELECT": {
            return [...acc, { fieldId: fieldSchema.id, tagId: value as string }];
          }
          case "TAG_MULTI_SELECT": {
            return [...acc, { fieldId: fieldSchema.id, tagIds: value as string }];
          }
          case "SELECT": {
            return [...acc, { fieldId: fieldSchema.id, value: (value as any).value }];
          }
          default:
            return [...acc, { fieldId: fieldSchema.id, value }];
        }
      }
      return acc;
    }, []) as IPatchPropertyByIdFieldPayload[];

    const payload: IPatchPropertyByIdPayload = {
      fields: payloadField,
      contactPhone: values.contactPhone,
      additionalPhone: values.additionalPhone,
      price: values.price,
    }

    await mutateAsync({
      propertyId,
      payload,
    }, {
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      }
    });

  }

  useEffect(() => {
    defaultValues && reset(defaultValues);
  }, [defaultValues]);

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    requiredFieldsCount: requiredFieldsCount,
  };
}

export default usePropertyFieldsForm;
