import React, { useMemo, useState } from "react";

import { EUserRole } from "../../../common/types/dto/user";
import { IChat, IChatParticipant } from "../types/chat";

import useAuthStore from "../../../common/stores/useAuthStore";

import useWebsocketChatStore from "../stores/useWebsocketChatStore";

import { InputUncontrolled } from "../../../common/components/Input/Input";

import ChatListButton from "./ChatListButton";

import MagnifyingGlass from "../../../common/assets/icons/MagnifyingGlass";

const ChatsList: React.FC = () => {
  const [searchFilter, setSearchFilter] = useState('');

  const { user } = useAuthStore();
  const { roomId, setRoomId, rooms } = useWebsocketChatStore();

  const chatListData = rooms.map((chat: IChat) => {
    const { id, unreadMessages, property, participants, isSupport, lastMessage } = chat;
    const opponent = participants.find(participant => participant.id !== user?.id) as IChatParticipant;
    const name = opponent?.role === EUserRole.Admin ? 'Support' : opponent?.name;
    const avatar = opponent?.avatar;

    return {
      id,
      name,
      avatar,
      address: property?.address,
      unreadMessages,
      isSupport,
      lastMessage,
    }
  });

  const buttonsMap = useMemo(() => chatListData
    .filter(({ name }) => name?.toLowerCase().includes(searchFilter.toLowerCase()))
    .sort((a, b) => {
      if (a.isSupport !== b.isSupport) {
        return a.isSupport ? -1 : 1;
      }

      return new Date(b.lastMessage?.createdAt || 0).getTime() - new Date(a.lastMessage?.createdAt || 0).getTime();
    })
    .map(({ name, address, avatar, unreadMessages, id, isSupport }) => (
      <ChatListButton key={id} onClick={() => setRoomId(id)} name={name} address={address} avatar={avatar} isActive={id === roomId} unreadMessages={unreadMessages} isSupport={isSupport} />
    )), [chatListData, searchFilter, roomId])

  return (
    <div className="w-[347px] bg-white p-5 pr-1 rounded-l-[15px] h-[calc(100vh-124px)] min-h-[538px]">
      <div className="relative pr-4">
        <InputUncontrolled
          type="text"
          className="flex items-center w-full h-[50px] pl-[50px] pr-[10px] mb-[25px]"
          placeholder="Search..."
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
        <MagnifyingGlass className="absolute top-1/2 left-[20px] transform -translate-y-1/2" />
      </div>
      <div className="flex flex-col gap-[24px] h-[calc(100vh-239px)] min-h-[370px] pr-4 overflow-y-auto">
        {buttonsMap}
      </div>
    </div>
  )
}

export default ChatsList;
