import { Link } from "react-router-dom";

import Input from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";

import useFormLogin from "../hooks/useFormLogin";

const LoginForm = () => {
  const {
    control,
    handleSubmit,
    isValid,
  } = useFormLogin();

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-5">
        <Input
          label="Email"
          name="email"
          control={control}
          placeholder="Enter your email address"
        />
        <Input
          type="password"
          label={(
            <div className="flex justify-between items-center">
              <div>Password</div>
              <div className="text-[12px] text-[#9c9aa5] underline">
                <Link to="/forgot-password">Forgot password?</Link>
              </div>
            </div>
          )}
          name="password"
          control={control}
          placeholder="Enter password"
        />
      </div>
      <div className="mt-[54px]">
        <Button
          type="submit"
          size="large"
          disabled={!isValid}
          fullWidth
        >
          Sign In
        </Button>
      </div>
    </form>
  )
}

export default LoginForm;
