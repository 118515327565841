import { useMemo } from "react";
import { createBrowserRouter } from "react-router-dom";

import { EUserRole } from "../types/dto/user";

import useAuthStore from "../stores/useAuthStore";

import routesUnauthorized from "../../routes/routes-unauthorized";
import routesAdmin from "../../routes/routes-admin";
import routesAgent from "../../routes/routes-agent";

const routerUnauthorized = createBrowserRouter(routesUnauthorized);
const routerAdmin = createBrowserRouter(routesAdmin);
const routerAgent = createBrowserRouter(routesAgent);

const useRouter = () => {
  const { user, accessToken, impersonationToken } = useAuthStore((state) => state);

  const router = useMemo(() => {
    if(!accessToken) return routerUnauthorized;
    if(!user) return null;
    return user.role === EUserRole.Admin && !impersonationToken ? routerAdmin : routerAgent;
  }, [user, accessToken, impersonationToken]);

  return {
    router,
  }
}

export default useRouter;
