import React from 'react';

import { patchPropertyApprove } from "../../../../../api/services/property/requests";

import useGlobalLoaderStore from "../../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";

import Button from "../../../../../common/components/Button/Button";

interface ApprovePropertyModalProps {
  id: string;
}

const ApprovePropertyModal: React.FC<ApprovePropertyModalProps> = ({ id }) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const handleApprove = async () => {
    try {
      setLoader(true)
      await patchPropertyApprove(id);
      onClose();
      location?.reload();
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="grid gap-[25px] w-[572px]">
      <div>
        <div className="text-[#333] text-[18px] leading-5 font-bold mb-2">Approve property</div>
        <div>Are you sure want to approve this property?</div>
      </div>
      <div className="flex items-center justify-end gap-[30px]">
        <div onClick={onClose} className="text-[16px] text-[#006CFB] font-medium cursor-pointer">Not now</div>
        <Button onClick={handleApprove} className="w-[198px] h-[48px] rounded-[8px] text-[16px] font-bold">Approve</Button>
      </div>
    </div>
  );
};

export default ApprovePropertyModal;
