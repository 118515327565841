import React from "react";
import { useNavigate } from "react-router-dom";

import { EUserRole } from "../../../common/types/dto/user";

import useAuthStore from "../../../common/stores/useAuthStore";

import useDialog from "../../../common/hooks/useDialog";

import Dialog from "../../../common/components/Dialog/Dialog";
import Button from "../../../common/components/Button/Button";

import LogoutIcon from "../../../common/assets/icons/LogoutIcon";
import NotificationIcon from "../../../common/assets/icons/NotificationIcon";

const Header = () => {
  const navigate = useNavigate();

  const { user, logout, impersonationToken, setImpersonationToken } = useAuthStore((state) => state)

  const handleLogout = () => {
    logout();
    navigate("/")
  }

   const handleUnImpersonate = () => {
     setImpersonationToken(null);
     navigate("/");
     location?.reload();
   }

  const dialog = useDialog();

  return (
    <>
      <header className="py-[20px] pr-[26px] flex justify-between items-center">
        <div className="flex items-center gap-[10px]">
          {!user?.avatar ? (
            <div className="flex-center rounded-[15px] w-[40px] h-[40px] bg-blue text-white">{user?.name.charAt(0)}</div>
          ) : (
            <img
              className="rounded-[15px] w-[40px] h-[40px]"
              src={user.avatar.url}
              alt="photo"
              width={40}
              height={40}
            />
          )}
          <div className="flex flex-col justify-center">
            <div className="text-[14px] text-[#11142D]">{user?.name}</div>
            {(user?.role === EUserRole.Agent || impersonationToken) && (<div className="text-[12px] text-[#808191]">ID: {user?.code}</div>)}
          </div>
        </div>
        <div className="flex items-center gap-[20px] ">
          {(user?.role === EUserRole.Agent || impersonationToken) && (<>
              <NotificationIcon/>
              <div className="flex-center gap-[10px] p-[6px] rounded-[10px] bg-blue text-[18px] text-white font-bold leading-[23px]"><div>Publications: 134</div></div>
            </>
          )}
          {impersonationToken && <div className="cursor-pointer" onClick={handleUnImpersonate}>
            <LogoutIcon color='orange'/>
          </div>}
          <div className="cursor-pointer" onClick={() => dialog.setOpen(true)}>
            <LogoutIcon/>
          </div>
        </div>
      </header>
      <Dialog
        open={dialog.open}
        setOpen={dialog.setOpen}
        title="Log Out"
        actions={
          <Button
            onClick={handleLogout}
            fullWidth={true}
          >
            Log Out
          </Button>
        }
      >
        Are you sure you want to log out of your account?
      </Dialog>
    </>
  );
}

export default Header;
