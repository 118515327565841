import React, { useCallback, useMemo } from "react";

import { TPropertyStatus } from "../../../../common/types/dto/property";

import useGlobalModalStore from "../../../../common/stores/useGlobalModalStore";

import ApprovePropertyModal from "../modals/ApprovePropertyModal/ApprovePropertyModal";
import RejectPropertyModal from "../modals/RejectPropertyModal/RejectPropertyModal";

import CheckIcon from "../../../../common/assets/icons/CheckIcon";
import CrossIcon from "../../../../common/assets/icons/CrossIcon";
import ThrashCan from "../../../../common/assets/icons/ThrashCan";

interface IPropertyActionsAdminProps {
  id?: string;
  status: TPropertyStatus;
}

//todo: check props elsewhere (id, property, etc)
const PropertyActionsAdmin: React.FC<IPropertyActionsAdminProps> = ({ id, status }) => {
  const { setIsOpen, setChildren } = useGlobalModalStore();

  if (!id) return <></>;

  const approveProperty = useCallback(async () => {
    setChildren(<ApprovePropertyModal id={id} />)
    setIsOpen(true);
  }, [id]);

  const rejectProperty = useCallback(() => {
    setChildren(<RejectPropertyModal id={id} />)
    setIsOpen(true);
  }, [id]);

  const deleteProperty = useCallback(() => {
    alert('not implemented')
  }, [id]);

  const items = useMemo(() => {
    switch (status) {
      case 'IN_MODERATE':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={rejectProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <CrossIcon width={24} height={24} color="red" />
            </button>
            <button onClick={approveProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <CheckIcon width={24} height={24} color={'green'} />
            </button>
          </div>
        )
      case 'ACTIVE':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={deleteProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <ThrashCan width={24} height={24} color="orange" />
            </button>
            <button onClick={rejectProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <CrossIcon width={24} height={24} color="#E53E3E" />
            </button>
          </div>
        )
      default:
        return <></>
    }
  }, [status, approveProperty, rejectProperty, deleteProperty])

  return (
    <>
      {items}
    </>
  )
}

export default PropertyActionsAdmin;
