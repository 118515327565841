import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { postAuthLogin } from "../../../api/services/auth/requests";

import { ILogin } from "../types/form";

import { loginFormSchema } from "../validations/schema";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useAuthStore from "../../../common/stores/useAuthStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

const useFormLogin = () => {
  const navigate = useNavigate();

  const { setAccessToken } = useAuthStore((state) => state);
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();

  const { mutateAsync } = useMutation((payload: ILogin) => postAuthLogin(payload));

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
  } = useForm<ILogin>({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      isAdminPanel: true,
    },
    resolver: zodResolver(loginFormSchema),
  })

  const onSubmit = async (value: ILogin) => {
    try {
      setLoader(true);
      const { accessToken } = await mutateAsync(value);
      setAccessToken(accessToken);
    } catch (error: any) {
      const needConfirmEmail = error?.response?.data?.data?.needConfirmEmail;

      if(needConfirmEmail) {
        setLoader(false);
        navigate(`/verify-email?email=${btoa(value.email)}`);
      }

      setError(error?.response?.data?.message || "Login failed");
    }
    finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
  };
}

export default useFormLogin;
