import React, { useState } from 'react';

import Button from "../../../../../common/components/Button/Button";
import Input from "../../../../../common/components/Input/Input";

import useFormChangePassword from "../../../hooks/useFormChangePassword";

import PasswordStrength from "../../../../create-account/components/PasswordStrength";

const ChangePasswordModalForm: React.FC = () => {
  const [validPassword, setValidPassword] = useState(false);

  const {
    control,
    handleSubmit,
    newPassword,
    isValid,
  } = useFormChangePassword();

  return (
      <form onSubmit={handleSubmit} className="grid gap-[14px] w-[572px]">
        <div>
          <h2 className="text-[#333] text-[18px] leading-5 font-medium mb-[10px]">Change password</h2>
          <div className="text-[14px] text-[#666]">Enter a new password for your account. Please make sure it meets the security requirements and confirm it by entering it again. Click “Update Password” to save your new password and regain access to your account.</div>
        </div>
        <Input
          type="password"
          label="Current Password"
          name="currentPassword"
          control={control}
          placeholder="Enter current password"
        />
        <Input
          type="password"
          label="New Password"
          name="newPassword"
          control={control}
          placeholder="Enter new password"
        />
        <Input
          type="password"
          label="Confirm New Password"
          name="confirmNewPassword"
          control={control}
          placeholder="Enter new password again"
        />
        <PasswordStrength
          password={newPassword}
          setValidPassword={setValidPassword}
        />
        <Button
          className="flex-center w-[249px] h-[48px] rounded-[8px] text-[16px] font-bold p-0 ml-auto"
          type="submit"
          disabled={!isValid || !validPassword}
        >Update password</Button>
      </form>
  );
};

export default ChangePasswordModalForm;
