import { useEffect } from "react";
import classNames from "classnames";

import useGlobalErrorStore from "../../stores/useGlobalErrorStore";

const TIME = 5000; // change only with animation time in tailwind.config.js

const Notification = () => {
    const { errors, removeError } = useGlobalErrorStore()

    useEffect(() => {
        errors.forEach((error) => {
            const timer = setTimeout(() => {
                removeError(error.id);
            }, TIME);

            return () => clearTimeout(timer);
        });
    }, [errors, removeError]);

    return (
      <div className="fixed top-[30px] right-[25px] grid gap-3 z-[5000]">
          {errors.slice().reverse().map((error, index) => (
            <div
              key={error.id}
              className={classNames(
                "flex items-center min-w-[358px] h-[42px] bg-[#fbebeb] rounded-lg text-[15px] text-[#000] pl-4 pr-8 relative",
                "animate-notification"
              )}
            >
                <span>{error.message}</span>
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xl"
                  onClick={() => removeError(error.id)}
                >
                    &times;
                </button>
            </div>
          ))}
      </div>
    );
}

export default Notification
