import React, { FC, useMemo, useState } from "react";

import { getAddressString } from "../../../common/utils/address";

import { IProperty } from "../../../common/types/dto/property";
import { ICategorySchema } from "../../../common/types/dto/category";

import usePropertyFieldsFromSchema from "../hooks/usePropertyFieldsFromSchema";

import ParametersIcon from "../../../common/assets/icons/property-fields/ParametersIcon";
import PriceIcon from "../../../common/assets/icons/property-fields/PriceIcon";
import ContactsIcon from "../../../common/assets/icons/property-fields/ContactsIcon";
import NoImageIcon from "../../../common/assets/icons/NoImageIcon";
import LocationIcon from "../../../common/assets/icons/LocationIcon";
import DescriptionIcon from "../../../common/assets/icons/property-fields/DescriptionIcon";
import FeatureIcon from "../../../common/assets/icons/property-fields/FeatureIcon";

import PropertyFieldWrapper from "./PropertyFieldWrapper";
import PropertyCarousel from "./PropertyCarousel";
import PropertyFieldsColumn from "./PropertyFieldsColumn";

interface IPropertyPreviewProps {
  property?: IProperty;
  categorySchema?: ICategorySchema;
}

const PropertyPreview: FC<IPropertyPreviewProps> = ({property, categorySchema}) => {
  const firstAvailableDescriptionLanguage = property?.description?.find((desc: any) => desc.value)?.language || 'cz'
  const [language, setLanguage] = useState(firstAvailableDescriptionLanguage)

  const propertyFields = usePropertyFieldsFromSchema(property?.fields, categorySchema?.groups?.find((g) => g.name === "parameters"));
  const priceAndRentalTermsFields = usePropertyFieldsFromSchema(property?.fields, categorySchema?.groups?.find((g) => g.name === "priceAndRentalTerms"));
  const featuresFields = usePropertyFieldsFromSchema(property?.fields, categorySchema?.groups?.find((g) => g.name === "features"));
  const contactFields = useMemo(() => {
    if (!property) return null;
    return [
      {
        label: "Email",
        value: property.owner.email,
      },
      ...(property.contactPhone ? [{
        label: "Contact phone number",
        value: property.contactPhone,
      }] : []),
      ...(property.additionalPhone ? [{
        label: "Additional phone number",
        value: property.additionalPhone,
      }] : []),
    ]
  }, [property])

  const propertyShowMoreLength = 200;
  const [descriptionShowMore, setDescriptionShowMore] = useState<boolean>(false);
  const propertyDescription = useMemo(() => {
    if (!property?.description?.length) return null;
    const descObject = property?.description?.find((description: any) => description.language === language);
    if (!descObject) return null;

    if (propertyShowMoreLength > descObject.value.length) return descObject.value;
    return descriptionShowMore ?
      descObject.value :
      `${descObject.value.slice(0, propertyShowMoreLength)}...`;
  }, [property, descriptionShowMore, language]);

  const handleChangeLanguage = () => {
    if (language === 'cz') {
      setLanguage('en')
    } else {
      setLanguage('cz')
    }
  }

  const allImages = useMemo(() => {
    if(!property) return null;

    const mainImage = property.mainImage;
    const collateralImages = property.gallery?.map(img => img.image) || []

    return mainImage ? [...[mainImage], ...collateralImages ] : collateralImages;
  }, [property])

  return (
    <div className="flex gap-[30px]">
      <div className="flex-1 w-0">
        <div>
          {allImages && allImages.length > 0 ? (
            <PropertyCarousel items={allImages}/>
          ) : (
            <div className="flex-center aspect-[2] bg-[#F8F8F8] rounded-[10px]">
              <NoImageIcon/>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center mt-[8px] font-Manrope">
          <div className="flex items-center gap-[6px]">
            <LocationIcon width={24} height={24}/>
            <div className="text-[18px] text-[#808191]">
              {getAddressString(property?.address)}
            </div>
          </div>
          <div>
            <div
              className="px-[9px] py-[7px] min-w-[120px] rounded-[4px] text-[#333333] text-[24px] font-semibold bg-[#006cfb0d] h-[44px] leading-[1.2] flex justify-center items-baseline">
              {property?.price && `Kč ${property?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`}
              <span className="text-[16px]">{property?.priceType === 'PER_MONTH' ? '/mo' : property?.priceType === 'PER_DAY' ? '/day' : ''}</span>
            </div>
          </div>
        </div>
        {!!propertyFields?.length && <div className="mt-[23px]">
          <PropertyFieldWrapper
            label="Parameters"
            icon={<ParametersIcon/>}
          >
            <div className="mt-[16px]">
              <PropertyFieldsColumn items={propertyFields}/>
            </div>
          </PropertyFieldWrapper>
        </div>}
        {!!property?.description.length && (
          <div className="relative mt-[23px]">
            <PropertyFieldWrapper
              label="Description"
              icon={<DescriptionIcon/>}
            >
              <div className="absolute top-0 right-4 cursor-pointer" onClick={handleChangeLanguage}>{language}</div>
              <div className="mt-[6px] text-[#666] leading-[1.6]">
                {propertyDescription}

                {property.description.length > propertyShowMoreLength && (
                  <>
                    {" "}
                    <span
                      className="cursor-pointer text-blue"
                      onClick={() => setDescriptionShowMore((s) => !s)}
                    >
                    {descriptionShowMore ? "show less" : "show more"}
                  </span>
                  </>
                )}
              </div>
            </PropertyFieldWrapper>
          </div>
        )}
        {!!featuresFields?.length && <div className="mt-[23px]">
          <PropertyFieldWrapper
            label="Features"
            icon={<FeatureIcon/>}
          >
            <div className="mt-[18px] line-clamp-3">
              <PropertyFieldsColumn items={featuresFields} />
            </div>
          </PropertyFieldWrapper>
        </div>}
      </div>
      <div className="w-[330px]">
        <div className="flex flex-col gap-[40px]">
          {!!priceAndRentalTermsFields?.length && <PropertyFieldWrapper
            label="Price and rental terms"
            icon={<PriceIcon/>}
          >
            <div className="mt-[18px]">
              <PropertyFieldsColumn items={priceAndRentalTermsFields}/>
            </div>
          </PropertyFieldWrapper>}
          <PropertyFieldWrapper
            label="Contacts"
            icon={<ContactsIcon/>}
          >
            <div className="mt-[18px]">
              <div className="flex items-center gap-[10px]">
                {!property?.owner?.avatar ? (
                  <div
                    className="flex-center rounded-[15px] w-[36px] h-[36px] bg-blue text-white">{property?.owner?.name.charAt(0)}</div>
                ) : (
                  <img
                    className="rounded-[15px] w-[36px] h-[36px]"
                    src={property.owner.avatar.url}
                    alt="photo"
                    width={40}
                    height={40}
                  />
                )}
                <div className="font-bold">{property?.owner.name}</div>
              </div>
              {!!contactFields?.length && <div className="mt-[20px]">
                <PropertyFieldsColumn items={contactFields}/>
              </div>}
            </div>
          </PropertyFieldWrapper>
        </div>
      </div>
    </div>
  );
}

export default PropertyPreview;
