import { ICategoryFull } from "../../../common/types/dto/category";

export const initCategoriesIndexFromCategoryTree = (array: number[], category: ICategoryFull): number[] => {
  if (category.children.length > 0) return initCategoriesIndexFromCategoryTree([...array, 0], category.children[0])
  return array;
}

export const getCategoryFromCategoriesIndex = (categories: ICategoryFull[], array: number[]): ICategoryFull => {
  let category: ICategoryFull | null = null;
  for (let index of array) {
    if (!category) category = categories[index]
    else category = category.children[index]
  }
  return category!;
}
