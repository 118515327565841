import React, { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import { getAddressString } from "../../common/utils/address";

import { getCategorySchemaById } from "../../api/services/category/requests";
import { getEditPropertyById } from "../../api/services/property/requests";

import { getSubpageName } from "../../features/property/helpers/propertyName";

import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../common/stores/useGlobalModalStore";

import usePropertyEditStore from "../../features/property/stores/usePropertyEditStore";

import usePropertyFieldsForm from "../../features/property/hooks/usePropertyFieldsForm";
import usePhotosAndDescriptionPropertyForm from "../../features/property/hooks/usePhotosAndDescriptionPropertyForm";
import usePropertyDispositionForm from "../../features/property/hooks/usePropertyDispositionForm";

import Button from "../../common/components/Button/Button";
import PageHeader from "../../common/components/PageHeader/PageHeader";
import CardWrapper from "../../common/components/CardWrapper/CardWrapper";

import PropertyFieldsForm from "../../features/property/components/PropertyFieldsForm";
import PropertyPreview from "../../features/property/components/PropertyPreview";
import PropertyPhotosAndDescription from "../../features/property/components/PropertyPhotosAndDescription";
import PropertyDisposition from "../../features/property/components/PropertyDisposition";
import PublishModalForm from "../../features/property/components/modals/PublishModalForm/PublishModalForm";
import PropertyEditList from "../../features/property/components/PropertyEditList";
import ChevronIcon from "../../common/assets/icons/ChevronIcon";

const PropertyEditPage = () => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { setIsOpen, setChildren } = useGlobalModalStore();

  const { flow, step, setStep } = usePropertyEditStore();

  let { id } = useParams();
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const {
    data,
    refetch,
  } = useQuery(["getPropertyWithSchema", id], async () => {
      setLoader(true);
      const property = await getEditPropertyById(id!);
      const categorySchema = await getCategorySchemaById(property.category.id);
      setLoader(false);

      return {
        property,
        categorySchema,
      };
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    }
  });

  const openModal = () => {
    if (!id) return;

    setChildren(<PublishModalForm id={id} />);
    setIsOpen(true);
  }

  const property = data?.property;
  const categorySchema = data?.categorySchema;

  const subpageName = getSubpageName(property);
  const parametersSchema = useMemo(() => categorySchema?.groups?.find((g) => g.name === "parameters"), [categorySchema])
  const featuresSchema = useMemo(() => categorySchema?.groups?.find((g) => g.name === "features"), [categorySchema])
  const priceAndRentalTermsSchema = useMemo(() => categorySchema?.groups?.find((g) => g.name === "priceAndRentalTerms"), [categorySchema])

  const {
    control: dispositionControl,
    handleSubmit: handleSubmitDisposition,
    requiredFieldsCount: requiredFieldsCountDisposition,
  } = usePropertyDispositionForm(id as string, property?.address);

  const {
    control: parametersControl,
    handleSubmit: handleSubmitParameters,
    requiredFieldsCount: requiredFieldsCountParameters,
  } = usePropertyFieldsForm(id as string, parametersSchema, property?.fields);
  const {
    control: featuresControl,
    handleSubmit: handleSubmitFeatures,
    requiredFieldsCount: requiredFieldsCountFeatures,
  } = usePropertyFieldsForm(id as string, featuresSchema, property?.fields);
  const {
    control: priceAndRentalTermsControl,
    handleSubmit: handleSubmitPriceAndRentalTerms,
    requiredFieldsCount: requiredFieldsCountPriceAndRentalTerms,
  } = usePropertyFieldsForm(id as string, priceAndRentalTermsSchema, property?.fields, property, 'priceAndRentalTerms');
  const {
    control: photoAndDescriptionControl,
    handleSubmit: photoAndDescriptionHandleSubmit,
    requiredFieldsCount: requiredFieldsCountPhotosAndDescription,
  } = usePhotosAndDescriptionPropertyForm(property);

  const { control, schema } = useMemo(() => {
    switch (step) {
      case "parameters":
        return {
          control: parametersControl,
          schema: parametersSchema,
        };
      case "features":
        return {
          control: featuresControl,
          schema: featuresSchema,
        };
      case "priceAndRentalTerms":
        return {
          control: priceAndRentalTermsControl,
          schema: priceAndRentalTermsSchema,
        };
      default:
        return {};
    }
  }, [
    step,
    parametersControl,
    parametersSchema,
    featuresControl,
    featuresSchema,
    priceAndRentalTermsControl,
    priceAndRentalTermsSchema,
  ]);

  const addressStringDefault = useMemo(() => getAddressString(property?.address), [property]);

  const coordinatesDefault = useMemo(() => {
    if (!property?.address?.coordinates?.lat || !property?.address?.coordinates?.lon) return undefined;
    return { lat: property.address.coordinates.lat, lng: property.address.coordinates.lon };
  }, [property]);

  const requiredFieldsCount = {
    disposition: requiredFieldsCountDisposition,
    parameters: requiredFieldsCountParameters,
    features: requiredFieldsCountFeatures,
    priceAndRentalTerms: requiredFieldsCountPriceAndRentalTerms,
    photosAndDescription: requiredFieldsCountPhotosAndDescription,
  };

  const allRequiredFieldsFilled = Object.values(requiredFieldsCount).every(value => !value);

  const handleExit = () => {
    navigate('/properties')
  };

  const handleSaveClick = async () => {
    try {
      switch (step) {
        case "disposition":
          await handleSubmitDisposition();
          await refetch();
          handleExit()
          break;
        case "parameters":
          await handleSubmitParameters();
          await refetch();
          handleExit()
          break;
        case "photosAndDescription":
          await photoAndDescriptionHandleSubmit();
          await refetch();
          handleExit()
          break;
        case "features":
          await handleSubmitFeatures();
          await refetch();
          handleExit()
          break;
        case "priceAndRentalTerms":
          await handleSubmitPriceAndRentalTerms();
          await refetch();
          handleExit()
          break;
      }
    } catch (error: any) {
      setError(error?.message || '');
    }

  };
  const handleNextClick = async () => {
    try {
      switch (step) {
        case "disposition":
          await handleSubmitDisposition();
          await refetch();
          setStep("parameters");
          break;
        case "parameters":
          await handleSubmitParameters();
          await refetch();
          setStep("photosAndDescription");
          break;
        case "photosAndDescription":
          await photoAndDescriptionHandleSubmit();
          await refetch();
          setStep("features");
          break;
        case "features":
          await handleSubmitFeatures();
          await refetch();
          setStep("priceAndRentalTerms");
          break;
        case "priceAndRentalTerms":
          await handleSubmitPriceAndRentalTerms();
          await refetch();
          setStep("preview");
          break;
      }
    } catch (error: any) {
      setError(error?.message || '');
    }
  };

  const handlePrevClick = () => {
    switch (step) {
      case "parameters":
        setStep("disposition");
        break;
      case "photosAndDescription":
        setStep("parameters");
        break;
      case "features":
        setStep("photosAndDescription");
        break;
      case "priceAndRentalTerms":
        setStep("features");
        break;
      case "preview":
        setStep("priceAndRentalTerms");
        break;
    }
  };

  const handleSaveToList = async () => {
    try {
      switch (step) {
        case "disposition":
          await handleSubmitDisposition();
          await refetch();
          setStep("list");
          break;
        case "parameters":
          await handleSubmitParameters();
          await refetch();
          setStep("list");
          break;
        case "photosAndDescription":
          await photoAndDescriptionHandleSubmit();
          await refetch();
          setStep("list");
          break;
        case "features":
          await handleSubmitFeatures();
          await refetch();
          setStep("list");
          break;
        case "priceAndRentalTerms":
          await handleSubmitPriceAndRentalTerms();
          await refetch();
          setStep("list");
          break;
      }
    } catch (error: any) {
      setError(error?.message || '');
    }
  };

  const handleBackToList = () => {
    setStep("list");
  };

  const saveDisabled = step !== 'photosAndDescription' && !!requiredFieldsCount[step as keyof typeof requiredFieldsCount];

  useEffect(() => {
    if (flow === 'create') {
      setStep('disposition');
    } else {
      setStep('list');
    }
  }, []);

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0);
  }, [step]);

  return (
    <div className="p-[20px_18px_16px_40px] min-h-full">
      {flow === "edit" ? (
        <PageHeader
          start={
            <>
              {step === "list" ? (
                <Link to="/properties">
                  <div className="bg-white rounded-[10px] w-[40px] h-[40px] flex-center border border-[#ccc]">
                    <ChevronIcon color="black"/>
                  </div>
                </Link>
              ) : (
                <button onClick={handleBackToList} className="bg-white rounded-[10px] w-[40px] h-[40px] flex-center border border-[#ccc]">
                  <ChevronIcon color="black"/>
                </button>
              )}
              {step === "list" ? "Edit property" : subpageName}
            </>
          }
        />
      ) : (
        <PageHeader
          backButton="/properties"
          start={subpageName}
        />
      )}
      <CardWrapper className="h-full flex flex-col overflow-x-auto pr-[13px] mt-[20px]" ref={containerRef}>
        <>
          {step === "list" && (
            <PropertyEditList requiredFieldsCount={requiredFieldsCount} />
          )}

          {step === "disposition" && (
            <PropertyDisposition control={dispositionControl} addressStringDefault={addressStringDefault} coordinatesDefault={coordinatesDefault} />
          )}

          {["parameters", "features", "priceAndRentalTerms"].includes(step) && (
            <PropertyFieldsForm schema={schema} control={control} priceType={property?.priceType} />
          )}

          {step === "photosAndDescription" && (
            <PropertyPhotosAndDescription
              control={photoAndDescriptionControl}
              propertyId={property?.id}
            />
          )}

          {step === "preview" && (
            <PropertyPreview property={property} categorySchema={categorySchema} />
          )}

          <div className="flex justify-end items-center gap-[30px] mt-[40px]">
            {flow === 'create' ? (
              <>
                {step === 'preview' ? (
                  <div className="cursor-pointer text-blue px-[18px]" onClick={handleExit}>
                    Exit
                  </div>
                ) : (
                  <div className="cursor-pointer text-blue px-[18px]" onClick={handleSaveClick}>
                    Save and exit
                  </div>
                )}

                {step !== 'disposition' && (
                  <div className="cursor-pointer text-blue px-[18px]" onClick={handlePrevClick}>
                    Previous step
                  </div>
                )}
                {step !== 'preview' ? (
                  <div className="w-[300px]">
                    <Button onClick={handleNextClick} fullWidth>
                      Next Step
                    </Button>
                  </div>
                ) : (
                  <div className="w-[300px]">
                    <Button disabled={!allRequiredFieldsFilled} onClick={openModal} fullWidth>
                      Publish
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                {step === "list" ? (
                  <div className="w-[300px]">
                    <Button disabled={!allRequiredFieldsFilled} onClick={openModal} fullWidth>Send to moderation</Button>
                  </div>
                ) : (
                  <div className="w-[300px]">
                    <Button disabled={saveDisabled} onClick={handleSaveToList} fullWidth>Save</Button>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      </CardWrapper>
    </div>
  );
}

export default PropertyEditPage;
