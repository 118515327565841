import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { TPropertyStatus } from "../../../../common/types/dto/property";

import useGlobalModalStore from "../../../../common/stores/useGlobalModalStore";

import usePropertyEditStore from "../../stores/usePropertyEditStore";

import PublishModalForm from "../modals/PublishModalForm/PublishModalForm";
import ArchiveModalForm from "../modals/ArchiveModalForm/ArchiveModalForm";

import EditIcon from "../../../../common/assets/icons/EditIcon";
import CrossIcon from "../../../../common/assets/icons/CrossIcon";
import PublishIcon from "../../../../common/assets/icons/PublishIcon";
import ArchiveIcon from "../../../../common/assets/icons/ArchiveIcon";
import WalletIcon from "../../../../common/assets/icons/WalletIcon";

interface IPropertyActionsAgentProps {
  id?: string;
  status: TPropertyStatus;
}

const PropertyActionsAgent: React.FC<IPropertyActionsAgentProps> = ({ id, status }) => {
  const navigate = useNavigate();

  const { setIsOpen, setChildren } = useGlobalModalStore();

  const { setFlow } = usePropertyEditStore();

  if (!id) return <></>;

  const editProperty = useCallback(() => {
    setFlow('edit');
    navigate(`/property/${id}/edit`);
  }, [id])

  const publishProperty = useCallback(() => {
    setChildren(<PublishModalForm id={id} />)
    setIsOpen(true);
  }, [id]);

  const archiveProperty = useCallback(() => {
    setChildren(<ArchiveModalForm id={id} status={status} />)
    setIsOpen(true);
  }, [id, status]);

  switch (status) {
      case 'DRAFT':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={publishProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <PublishIcon width={24} height={24} />
            </button>
            <button onClick={editProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <EditIcon width={24} height={24}/>
            </button>
          </div>
        )
      case 'IN_MODERATE':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={editProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <EditIcon width={24} height={24}/>
            </button>
            <button onClick={archiveProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <CrossIcon width={24} height={24} color="black" />
            </button>
          </div>
        )
      case 'UNPAID':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={publishProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <WalletIcon width={24} height={24} />
            </button>
            <button onClick={editProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <EditIcon width={24} height={24}/>
            </button>
          </div>
        )
      case 'ACTIVE':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={archiveProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <ArchiveIcon width={24} height={24} color="black" />
            </button>
            <button onClick={editProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <EditIcon width={24} height={24}/>
            </button>
          </div>
        )
      case 'ARCHIVE':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={publishProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <PublishIcon width={24} height={24} />
            </button>
            <button onClick={editProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <EditIcon width={24} height={24}/>
            </button>
          </div>
        )
      case 'REJECTED':
        return (
          <div className="flex items-center gap-[10px]">
            <button onClick={archiveProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <ArchiveIcon width={24} height={24} color="black" />
            </button>
            <button onClick={editProperty} className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <EditIcon width={24} height={24}/>
            </button>
          </div>
        )
      default:
        return <></>
    }
}

export default PropertyActionsAgent;
