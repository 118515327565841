import React from 'react';
import { useForm } from "react-hook-form";

import useGlobalLoaderStore from "../../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";

import Button from "../../../../../common/components/Button/Button";
import NumericInput from "../../../../../common/components/NumericInput/Input";

interface IDecreaseModalProps {
  id: string;
}

const DecreaseModal: React.FC<IDecreaseModalProps> = ({ id }) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const {
    control,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      amount: 0,
    }
  });

  const amount = watch("amount");

  const onSubmit = async (value: { amount: number; }) => {
    try {
      setLoader(true);
      // await patchDecreaseAmount({ amount: value.amount });
      alert(value.amount);
      onClose();
      location?.reload();
    } catch (error: any) {
      setError(error?.response?.data?.message || '');
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="grid gap-6 w-[438px]">
      <div className="text-[#333] text-[18px] leading-5 font-bold">Remove listings</div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1.5">
          <NumericInput
            type="number"
            control={control}
            placeholder='25 000'
            name="amount"
            classnames="text-center w-[88px] h-[27px] border border-[#006CFB] rounded-[4px] text-[14px] text-[#A0AFC4] px-2 outline-none"
            unstyled
            required
          />
          <div className="text-[12px] text-[#A0AFC4]">Publikace</div>
        </div>

        <Button color="red" className="w-[248px] h-[48px] rounded-[8px] text-[16px] font-bold" disabled={!amount} onClick={handleSubmit(onSubmit)}>Remove</Button>
      </div>
    </div>
  );
};

export default DecreaseModal;
