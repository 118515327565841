import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { postOtpPasswordResetCheck } from "../../../api/services/otp/requests";

import { IForgotPasswordPin } from "../types/form";

import { forgotPasswordPinFormSchema } from "../validations/schema";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import useForgotPasswordStore from "../stores/useForgotPasswordStore";

const useFormForgotPasswordPin = () => {
  const { setStep, email, setPin } = useForgotPasswordStore((state) => state);
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const {
    control,
    handleSubmit,
    watch,
  } = useForm<IForgotPasswordPin>({
    mode: "onBlur",
    defaultValues: {
      pin: ""
    },
    resolver: zodResolver(forgotPasswordPinFormSchema),
  });

  const pin = watch("pin");

  const onSubmit = async ({pin}: IForgotPasswordPin) => {
    try {
      setLoader(true);
      const {data} = await postOtpPasswordResetCheck({
        otp: pin,
        email,
      });

      if(data.valid){
        setPin(pin);
        setStep("new-password");
      }
      else {
        setError("Pin is not valid");
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || "Request failed")
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (pin.length === 4) handleSubmit(onSubmit)();
  }, [pin]);


  return {
    control,
    handleSubmit: handleSubmit(onSubmit),

  }
}

export default useFormForgotPasswordPin;
