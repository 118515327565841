import usePropertyEditStore, { TPropertyEditStoreStep } from "../stores/usePropertyEditStore";

import ChevronIcon from "../../../common/assets/icons/ChevronIcon";

const list: { title: string; description: string; step: TPropertyEditStoreStep }[] = [
  {
    title: 'Disposition',
    description: 'Provide details about the property’s location, including proximity to key amenities and transportation.',
    step: 'disposition',
  },
  {
    title: 'Photos and description',
    description: 'Upload high-quality images and provide a detailed description of the property’s key features.',
    step: 'photosAndDescription',
  },
  {
    title: 'Parameters',
    description: 'List essential property parameters such as size, ceiling height, and construction year.',
    step: 'parameters',
  },
  {
    title: 'Features',
    description: 'Highlight unique features like panoramic views, smart home systems, or proximity to amenities.',
    step: 'features',
  },
  {
    title: 'Price and Terms',
    description: 'State the price and include details on payment terms, lease conditions, or additional costs.',
    step: 'priceAndRentalTerms',
  },
];

const PropertyEditList = ({ requiredFieldsCount }: { requiredFieldsCount: any}) => {
  const { setStep } = usePropertyEditStore();

  const handleClick = (step: TPropertyEditStoreStep) => {
    setStep(step);
  };

  const listMap = list.map(({ title, description, step}) => (
    <div key={step} onClick={() => handleClick(step)} className="w-full h-[60px] flex justify-between items-center cursor-pointer">
      <div className="h-full flex flex-col justify-between">
        <div className="text-[20px] text-[#333] font-medium">{title}</div>
        <div className="text-[#666]">{description}</div>
      </div>
      <div className="flex items-center gap-[25px]">
        {!!requiredFieldsCount[step] && (
          <div className="text-[#C73434]">
            {`Fill required items: ${requiredFieldsCount[step]}`}
          </div>
        )}
        <ChevronIcon width={20} height={20} color="black" className="transform rotate-180" />
      </div>
    </div>
  ));

  return (
    <div className="flex flex-col gap-10">
      {listMap}
    </div>
  )
}

export default PropertyEditList;
