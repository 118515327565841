import React from 'react';

import { patchPropertyArchive } from "../../../../../api/services/property/requests";

import { TPropertyStatus } from "../../../../../common/types/dto/property";

import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";
import useGlobalLoaderStore from "../../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../../common/stores/useGlobalErrorStore";

import Button from "../../../../../common/components/Button/Button";

interface ArchiveModalFormProps {
  id: string;
  status: TPropertyStatus;
}

const ArchiveModalForm: React.FC<ArchiveModalFormProps> = ({ id, status }) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const movePropertyToArchive = async () => {
    try {
      setLoader(true)
      await patchPropertyArchive(id);
      onClose();
      location?.reload();
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="grid gap-[25px] w-[428px]">
      <div>
        <div className="text-[#333] text-[18px] leading-5 font-bold mb-2">Change status</div>
        <div>{status === 'IN_MODERATE' ? 'Are you sure you want to cancel moderation and move this listing to archive?' : 'Are you sure you want to move this listing to the archive?'}</div>
      </div>
      <div className="flex items-center justify-end gap-[30px]">
        <div onClick={onClose} className="text-[16px] text-[#006CFB] font-medium cursor-pointer">Not now</div>
        <Button className="w-[198px] h-[48px] rounded-[8px] text-[16px] font-bold" onClick={movePropertyToArchive}>Move to archive</Button>
      </div>
    </div>
  );
};

export default ArchiveModalForm;
