import React, { CSSProperties } from "react";
import ReactPinInput from 'react-pin-input';
import { Controller, ControllerProps } from "react-hook-form";
import classNames from "classnames";

import "./PinInput.css"

type TInputType = "numeric" | "custom";

interface IPinInputProps<TFormData extends Record<string, any>>
  extends Pick<ControllerProps<TFormData>, 'control' | 'name' | 'defaultValue'> {
  length: number;
  type?: TInputType;
  inputMode?: string;
  secret?: boolean;
  secretDelay?: number;
  disabled?: boolean;
  focus?: boolean;
  onChange?: (value: string, index: number) => void;
  onComplete?: (value: string, index: number) => void;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  inputFocusStyle?: CSSProperties;
  validate?: (value: string) => string;
  autoSelect?: boolean;
  regexCriteria?: any;
  ariaLabel?: string
  placeholder?: string
  error?: boolean;
  errorText?: string;
}

function PinInput<TFormData extends Record<string, any>>({
                                                           name,
                                                           control,
                                                           defaultValue,
                                                           onChange,
                                                           error,
                                                           errorText,
                                                           ...props
                                                         }: IPinInputProps<TFormData>): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({field, fieldState}) =>
        <div className={classNames({
          "error-wrapper": error ?? !!fieldState.error,
        })}>
          <ReactPinInput
            {...props}
            initialValue={field.value}
            onChange={(value, index) => {
              field.onChange(value);
              onChange?.(value, index);
            }}
          />
          {(errorText ?? fieldState.error?.message) && (
            <div
              className='top-[82px] font-medium text-error text-sm max-w-full text-center mt-[8px]'>
              {errorText ?? fieldState.error?.message}
            </div>
          )}
        </div>
      }/>);
}

export default PinInput;
