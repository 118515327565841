import React from "react";
import { useNavigate } from "react-router-dom";

import useWebsocketChatStore from "../../messages/stores/useWebsocketChatStore";

import Button from "../../../common/components/Button/Button";

import QuestionIcon from "../../../common/assets/icons/QuestionIcon";

const MenuDocumentationBlock = () => {
  const navigate = useNavigate();

  const { setOpenSupport } = useWebsocketChatStore()

  const handleClick = () => {
    setOpenSupport(true);
    navigate('/messages');
  };

  return (
    <div className="rounded-[16px] bg-blue p-[12px] bg-menu-documentation-block">
      <div>
        <div className="bg-white rounded-[12px] flex-center h-[32px] w-[32px]">
          <QuestionIcon/>
        </div>
      </div>
      <div className="mt-[18px] font-bold text-[14px]">Need help?</div>
      <div className="text-[12px]">Place check our docs</div>
      <div className="mt-[8px]">
        <Button
          onClick={handleClick}
          color="white"
          size="small"
          fullWidth={true}
          className="!rounded-[12px] !text-[10px] font-bold"
        >
          Support Chat
        </Button>
      </div>
    </div>
  )
}

export default MenuDocumentationBlock;
