import DashboardPropertiesBlock from "../../features/dashboard/components/DashboardPropertiesBlock";
import DashboardActivePropertiesBlock from "../../features/dashboard/components/DashboardActivePropertiesBlock";

const DashboardPage = () => {
  return (
    <div className="px-[25px] py-[18px] font-Manrope">
      <h1 className="text-[#11142d] text-[18px] font-semibold mb-5">Dashboard</h1>
      <div className="mb-6">
        <DashboardPropertiesBlock />
      </div>
      {/*<div className="mt-[20px]">*/}
      {/*  <DashboardTotalRevenueBlock />*/}
      {/*</div>*/}
      {/*<div className="mt-[20px]">*/}
      {/*  <DashboardOverviewBlock />*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*  <DashboardLastAddedBlock />*/}
      {/*</div>*/}
      <div>
        <DashboardActivePropertiesBlock />
      </div>
    </div>
  )
}

export default DashboardPage;
