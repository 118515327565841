import React from "react";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";

import { getAdminSettings, postAdminSettings } from "../../api/services/settings/requests";

import { TPostAdminSettings } from "../../api/services/settings/types";

import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";

import Textarea from "../../common/components/Textarea/Textarea";
import Button from "../../common/components/Button/Button";

interface IAdminSettings {
  settings: any;
}

const SettingsPage = () => {
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();

  const defaultValues = {
    settings: '',
  }

  const {
    control,
    setValue,
    handleSubmit,
    watch,
  } = useForm<IAdminSettings>({
    mode: 'onBlur',
    defaultValues,
  });

  useQuery(["settings"], async () => {
    setLoader(true);
    const data = await getAdminSettings();

    setValue('settings', JSON.stringify(data))
    setLoader(false);
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    }
  });

  const settings = watch('settings')

  const onSubmit = async (value: IAdminSettings) => {
    setLoader(true);

    const payload: TPostAdminSettings = value.settings

    try {
      setLoader(true)
      await postAdminSettings(payload);
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false)
    }
  }

  return (<div className="p-[25px]">
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6 h-full p-5 rounded-[15px] overflow-x-auto bg-white min-h-[calc(100vh-130px)]">
      <div>
        <Textarea
          label="Custom JSON options"
          name="settings"
          control={control}
          className="bg-[#f5f5f5]"
        />
      </div>
      <div className="flex justify-end">
        <Button className="w-[358px] h-[48px] text-[16px] font-bold" disabled={!settings.length} type="submit">Update profile</Button>
      </div>
    </form>
  </div>)
}

export default SettingsPage;
