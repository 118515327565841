import { useState } from "react";

import Input from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";
import PhoneInput from "../../../common/components/PhoneInput/PhoneInput";

import useFormCreateAccountBase from "../hooks/useFormCreateAccountBase";

import PasswordStrength from "./PasswordStrength";

const CreateAccountBaseForm = () => {
  const [validPassword, setValidPassword] = useState(false);

  const {
    control,
    handleSubmit,
    password,
    isValid,
  } = useFormCreateAccountBase();

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex justify-between items-center w-[1206px]">
        <div className="w-[400px] h-[510px] p-5 bg-[#F2F8FF59] ">
          <h3 className="text-[24px] text-[#333333] leading-[34px] mb-5">Company information</h3>
          <div className="grid gap-[25px]">
            <Input
              label="Legal name"
              name="legalName"
              control={control}
              placeholder="Enter legal name"
            />
            <Input
              label="IČ"
              name="ic"
              control={control}
              placeholder="Enter IČ"
            />
            <Input
              label="Company address"
              name="address"
              control={control}
              placeholder="Enter street, build number"
            />
            <PhoneInput
              label="Company Phone Number"
              name="companyPhone"
              control={control}
              defaultCountry="CZ"
              placeholder="Enter phone number"
            />
          </div>
        </div>
        <div>
          <h3 className="text-[24px] text-[#333333] leading-[34px]">Personal information</h3>
          <div className="text-[14px] text-[#666666] leading-5 mb-5">Your name, phone number and email will be available to all.</div>
          <div className="grid grid-cols-2 gap-y-6 gap-x-7 w-[752px]">
            <Input
              label="Full Name"
              name="name"
              control={control}
              placeholder="Enter your full name"
            />
            <Input
              type="password"
              label="Password"
              name="password"
              control={control}
              placeholder="Enter your new password"
            />
            <Input
              label="Email"
              name="email"
              control={control}
              placeholder="Email your email address"
            />
            <PasswordStrength
              password={password}
              setValidPassword={setValidPassword}
            />
            <PhoneInput
              label="Phone number"
              name="phone"
              control={control}
              defaultCountry="CZ"
              placeholder="Enter phone number"
            />
            <Input
              type="password"
              label="Confirm password"
              name="confirmPassword"
              control={control}
              placeholder="Enter password again"
            />
          </div>
        </div>
      </div>
      <div className="flex-center">
        <div className="mt-[25px] mb-[42px] w-[358px]">
          <Button
            type="submit"
            size="large"
            disabled={!isValid || !validPassword}
            fullWidth={true}
          >
            Sign up
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateAccountBaseForm;
