import React, { FC } from "react";

import useGlobalModalStore from "../../../common/stores/useGlobalModalStore";

import BillingModalForm from "./modals/BillingModalForm/BillingModalForm";

interface PublicationsPlanCardProps {
  amount: number;
  price: number;
}

const PublicationsPlanCard: FC<PublicationsPlanCardProps> = ({ amount, price }) => {
  const { setIsOpen, setChildren } = useGlobalModalStore();
  
  const cost = price / amount

  const openModal = () => {
    setChildren(<BillingModalForm price={price} cost={cost} />);
    setIsOpen(true);
  }

  return (
    <div className="flex flex-col justify-between w-[180px] h-[188px] py-5 px-3 rounded-[12px] bg-blue text-white leading-[1.2]">
      <div>
        <div className="whitespace-nowrap mb-[5px]">
          <span className="tracking-tighter text-[18px] font-semibold">{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</span> publications
        </div>
        <div className="text-[12px]">{cost} Kč/publication</div>
      </div>
      <div>
        <div className="text-[18px] font-semibold whitespace-nowrap mb-2.5">
          {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Kč
        </div>
        <div>
          <button onClick={openModal} className="flex-center w-full h-[29px] rounded-[8px] bg-white text-blue">Pay</button>
        </div>
      </div>
    </div>
  )
}

export default PublicationsPlanCard;
